.App {
  text-align: center;
  background-color: black;
}

html, body, body > div, .app, .App {
  
  background-color: black;
  height: 100%;
  font-family: 'Tenor Sans', sans-serif;

}

MuiTypography-h5{
  font-family: 'Tenor Sans', sans-serif;
}

a:link {
  color: black;
  text-decoration: none;

}

/* visited link */
a:visited {
  color: black;
  text-decoration: none;

}

hr{
  margin-top: 0;
  margin-bottom: 0;
  padding-top:0;
  padding-bottom: 0;
  border: 0;
  height: 6px;
  background-image: linear-gradient(to right, rgba(255, 223, 0, 100), rgba(91, 10, 145, 100), rgba(255, 223, 0, 100));
}

@import url('https://fonts.googleapis.com/css2?family=Tenor+Sans&display=swap');


